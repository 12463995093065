////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-secondaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-thirdColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-fourthColor {
  background-color: rgba(255, 102, 0, 1);
}
.bg-fifthColor {
  background-color: rgba(255, 102, 0, 0.67);
}
.bg-sixthColor {
  background-color: rgba(255, 102, 0, 1);
}
.bg-seventhColor {
  background-color: rgba(220, 93, 8, 1);
}
